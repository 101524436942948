/**
 * The main App component
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';


// Helpers
import { getData, storeData } from '../helpers/helpers';

// Config
import { config, setToken } from '../config';

// Routes
import { routes } from '../routes';

// Dependencies
import jwt_decode from 'jwt-decode';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { LaunchDarkly } from 'react-launch-darkly';

// Import UI components
import Header from './ui/Header';

// Import common components
import Dialog from './common/Dialog';
import Toast from './common/Toast';

// Import views
import DomainSelect from './views/DomainSelect';
import Home from './views/Home';
import Schedule from './views/schedule/Schedule';
import AppointmentLookup from './views/appointment/AppointmentLookup';
import EmailSent from './views/appointment/EmailSent';
import ProvisionExam from './views/provision/ProvisionExam';
import AppointmentDetails from './views/appointment/AppointmentDetails';

// Create history and listen for changes to track pageviews
const history = createBrowserHistory();
let gaTimeout = null;

history.listen(location => {
  if(window.ga) {
    trackPageview(location.pathname + location.search);
  }
});

// Track the page view on initial load
trackPageview(history.location.pathname + history.location.search);

/**
 * trackPageview - Sends a pageview event to Google Analytics
 * @param {string} url
 */
function trackPageview(url) {
  if(url.indexOf('?token') > -1) {
    url = '/';
  }

  // Wait until ga function is available
  const gaDelay = setInterval(function(){
    if(window.ga) {
      clearInterval(gaDelay);

      // Make sure we don’t send the pageview multiple times
      gaTimeout = setTimeout(function(){
        clearTimeout(gaTimeout);
        const cleanUrl = sanitizeUrl(url);
        window.ga('send', 'pageview', cleanUrl);
      }, 500);
    }
  }, 100);
}

/**
 * sanitizeUrl - replaces variables (such as SIDs) in a URL
 * @param {string} url
 */
function sanitizeUrl(url) {
  // Make sure there’s a leading slash
  if(url.charAt(0) !== '/') {
    url = `/${url}`;
  }

  if(url.indexOf('/schedule/EX') > -1) {
    // Exam SID
    url = url.replace(/(\/schedule\/)\w+/, '$1{examSid}');
  }

  if(url.indexOf('/reschedule/EX') > -1) {
    // Exam SID
    url = url.replace(/(\/reschedule\/)\w+/, '$1{examSid}');
  }

  return url;
}

class App extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.handleToken = this.handleToken.bind(this);

    // Set the initial state
    this.state = {
      render: false,
      agrLoaded: false
    };
  }

  componentDidMount() {
    const { DomainStore } = this.props.store;

    // Inject the agreements script
    this.injectAgreements();

    // Add token to session storage
    DomainStore.checkDomain(this.handleToken);

    // Aids with button focus styles
    document.querySelector('body').addEventListener('click', function(e){
      if(e.target.className && typeof e.target.className === 'string') {
        if(e.target.className.indexOf('btn') > -1) {
          if(e.target.className.indexOf('btn--clicked') === -1) {
            e.target.className += ' btn--clicked';
          }

          e.target.addEventListener('blur', function(e){
            e.target.className = e.target.className.replace(' btn--clicked', '');
          })
        }
      }
    });

    // Add Google Analytics tracking code if on production
    if(process.env.REACT_APP_ENVIRONMENT === 'production') {
      this.addGATracking();
    }
  }

  renderZDChat() {
    const zdScript = document.querySelector('#ze-snippet');

    if(!zdScript) {
      let zdc = document.createElement('script');
      zdc.id = 'ze-snippet';
      zdc.type = 'text/javascript';
      zdc.async = true;
      zdc.src = 'https://static.zdassets.com/ekr/snippet.js?key=a3206868-bdac-4af5-a921-ee51087a581e';

      zdc.onload = function() {
        window.zE(function(){
          window.zE('webWidget', 'updateSettings', {
            webWidget: {
              offset: {
                vertical: '44px'
              }
            }
          });
        });
      }

      document.body.appendChild(zdc);
    }

    return null;
  }

  /**
   * addGATracking - Adds Google Analytics tracking code
   */
  addGATracking() {
    const gaTrackingId = 'UA-144336988-1';
    const existingGAScript = document.querySelector('#ga-script');

    if(!existingGAScript) {
      const gaScript = document.createElement('script');
      gaScript.id = 'ga-script';
      gaScript.type = 'text/javascript';
      gaScript.async = true;
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;

      // Append the script
      document.body.append(gaScript);

      // Configure Analytics
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', gaTrackingId);

      // Create tracker
      const gaDelay = setInterval(function(){
        if(window.ga) {
          clearInterval(gaDelay);
          window.ga('create', gaTrackingId, 'auto');
        }
      }, 100);
    }
  }

  /**
   * handleToken - Stores the token and gets initial data
   */
  handleToken() {
    const { AppStore } = this.props.store;

    if(AppStore.token) {
      storeData('token', AppStore.token);

      // Check authorization
      this.checkAuthorization();

      // Add the token to the API header and store it in AppStore
      setToken(getData('token'));
      AppStore.token = getData('token');

      // Load translation
      if(!AppStore.translation) {
        AppStore.getTranslation();
      }

      // Get the machine’s time zone
      if(!AppStore.timeZone) {
        AppStore.getUserTimeZone();
      }

      // Get languages list
      if(!AppStore.languagesList || AppStore.languagesList.length === 0){
        AppStore.listLanguages()
      }

      // Get initial data (exams, proctor group, and proctors);
      this.getData();
    } else {
      this.setState({
        render: true
      });
    }
  }

  /**
   * injectAgreements - Injects the agreement engine script
   */
  injectAgreements() {
    const _this = this;
    const agrScript = document.querySelector('#agr-ui');

    if(!agrScript) {
      const agr = document.createElement('script');
      agr.id = 'agr-ui';
      agr.type = 'text/javascript';
      agr.async = true;

      if(process.env.REACT_APP_ENVIRONMENT === 'production') {
        agr.src = 'https://s3.amazonaws.com/cdn.smarterservices.com/agreements/js/agreements.min.js';
      } else {
        agr.src = 'https://s3.amazonaws.com/smarterservices-static-staging/agreements-ui/js/agreements.min.js';
      }

      agr.onload = function() {
        _this.setState({
          agrLoaded: true
        });
      }

      document.body.appendChild(agr);
    }
  }

  checkAuthorization() {
    const { AppStore } = this.props.store;
    let token = AppStore.token;
    let decodedToken = null;

    // Interval to ensure the token is there
    const tokenInterval = setInterval(function(){
      if(!token) {
        token = getData('token');
      }

      if(token) {
        clearInterval(tokenInterval);

        decodedToken = jwt_decode(token);

        if(decodedToken.errorCode === '1110') {
          // If the token is unauthorized, redirect
          window.location = `/#/unauthorized?token=${token}`;
        } else {
          // Otherwise, hit the check token endpoint
          AppStore.checkToken();
        }
      } else if(!token) {
        clearInterval(tokenInterval);
        window.location = `/#/unauthorized`;
      }
    }, 100);
  }

  getData() {
    const { ProctorStore, AppStore } = this.props.store;
    const _this = this;

    // These API calls rely on user data, so wait until that has been retrieved
    const dataDelay = setInterval(function(){
      if(AppStore.userData) {
        clearInterval(dataDelay);

        // Set render to true
        _this.setState({
          render: true
        });

        // Get proctor groups
        ProctorStore.listProctorGroups(false);
      }
    }, 100);
  }

  // Sets the spTestingUser variable if this spTestingUser query param is set
  setRemoteTestingOption(location) {
    if(new URLSearchParams(location.search).get("spTestingUser")) {
      localStorage.setItem('spTestingUser', true);
    }
  }

  render() {
    const { AppStore } = this.props.store;

    const ldConfig = {
      key: localStorage.getItem('spTestingUser') ? 'SP Testing User' : Date.now()
    };

    if(this.state.render) {
      return (
        <Router history={history}>
          <LaunchDarkly clientId={config.ldApiKey} user={ldConfig}>
            <div className="viewport">
              <Header {...this.props} />

              <Route render={({location}) => (
                <div id="main" className="content">
                  {this.setRemoteTestingOption(location)}
                  <TransitionGroup>
                    <CSSTransition
                      classNames="animation--fade"
                      timeout={300}
                      key={location.pathname}
                    >
                      <Switch location={location}>
                        <Route exact path={routes.index} render={(routing) => (
                          <DomainSelect
                            {...routing}
                            {...this.props}
                          />
                        )}/>

                        <Route path={routes.schedule} render={(routing) => (
                          <Schedule
                            {...routing}
                            {...this.props}
                          />
                        )}/>

                        <Route path={routes.reschedule} render={(routing) => (
                          <Schedule
                            {...routing}
                            {...this.props}
                            reschedule={true}
                          />
                        )}/>

                        <Route exact path={routes.modifyAppointment.lookup} render={(routing) => (
                          <AppointmentLookup
                            {...routing}
                            {...this.props}
                          />
                        )}/>

                        <Route exact path={routes.modifyAppointment.emailSent} render={(routing) => (
                          <EmailSent
                            {...routing}
                            {...this.props}
                          />
                        )}/>

                        <Route exact path={routes.provisionExam.provision} render={(routing) => (
                          <ProvisionExam
                            {...routing}
                            {...this.props}
                          />
                        )}/>
                        <Route exact path = {routes.appointmentDetails} render={(routing) => (
                          <AppointmentDetails
                            {...routing}
                            {...this.props}
                            remote
                          />
                        )}/>
                        <Route exact path = {routes.appointmentSetup} render={(routing) => (
                          <AppointmentDetails
                            {...routing}
                            {...this.props}
                            remote
                          />
                        )}/>

                        <Route path={routes.home} render={(routing) => (
                          <Home
                            {...routing}
                            {...this.props}
                            handleToken={this.handleToken}
                          />
                        )}/>
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </div>
              )} />

              {AppStore.dialog &&
                <Dialog {...this.props} />
              }

              {AppStore.toast &&
                <Toast
                  {...this.props}
                  content={AppStore.toast}
                />
              }

              {AppStore.loading &&
                <aside className="loader">
                  <div className="loader__msg">
                    <span className="meta">Loading…</span>
                  </div>
                </aside>
              }

              {/* Some logic or somethin &&*/
              this.renderZDChat()
              }
            </div>
          </LaunchDarkly>
        </Router>
      )
    }

    return null;
  }
}

export default observer(App);
